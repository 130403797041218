
<template>
	<div class="tcontainer">
		<div style="margin: 10px;">
			<Calendar @hide="loadLazyData" placeholder="Select Range" v-model="dateRange" selectionMode="range" dateFormat='dd/mm/yy' />
		</div>

		<div class="clearfix ttitle">
			<button @click="showAddEdit()" class="pull-right btn btn-success"><i class="fa fa-plus"></i></button>
			<button type="button" class="pull-right btn btn-primary" style="margin-right: 10px;" @click="exportCSV($event)" >
				<i class="fa-solid fa-file-csv"></i>
			</button>
			<div class="title">Manage Centers</div>
		</div>
		<DataTable :value="centers" :lazy="true" :resizableColumns="true" columnResizeMode="fit" :paginator="true"
			:rows="100" v-model:filters="filters" ref="dt" :totalRecords="totalRecords"
			:rowsPerPageOptions="[100, 200, 500, 1000]" :loading="loading" @page="onPage($event)" @sort="onSort($event)"
			@filter="onFilter($event)" filterDisplay="row" responsiveLayout="scroll" exportFilename="centers">
			<Column field="id" header="Id" filterField="id" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Id" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['id'] }}
					</div>
				</template>
			</Column>
			<Column field="code" header="Code" filterField="code" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Code" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['code'] }}
					</div>
				</template>
			</Column>
			<Column field="name" header="Name" filterField="name" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Name" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						<b>{{ data['name'] }}</b>
					</div>
				</template>
			</Column>
			<Column field="email" header="Email" filterField="email" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Email" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['email'] }}
					</div>
				</template>
			</Column>
			<Column field="password" header="Password" filterField="password" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Password" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['password'] }}
					</div>
				</template>
			</Column>
			<Column field="contact_person" header="Contact Person" filterField="contact_person" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Contact Person" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['contact_person'] }}
					</div>
				</template>
			</Column>
			<Column field="phone" header="Phone" filterField="phone" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Phone" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['phone'] }}
					</div>
				</template>
			</Column>
			<Column field="address" header="Address" filterField="address" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Address" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['address'] }}
					</div>
				</template>
			</Column>
			<Column field="created_at" header="Created At" filterField="created_at" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Created At" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ format(data['created_at'], "Do MMMM YY") }}
					</div>
				</template>
			</Column>
			<Column field="agent_name" header="Agent" filterField="agent_name" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Agent / Source" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['agent_name'] }}
					</div>
				</template>
			</Column>
			<Column field="balance" header="Balance" filterField="balance" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Balance" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['balance'] }}
					</div>
				</template>
			</Column>
			<Column
				header="Options &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ">
				<template #body="{ data }">
					<div v-if="!isSuper" class="tdata options">
						<button class="btn btn-sm btn-outline-success" @click="login(data)"><i class="fa fa-unlock-alt"></i></button>
						<button class="btn btn-sm btn-outline-warning" @click="showAddEdit(data)">
							<i class="fa fa-edit"></i>
						</button>
					</div>
					<div v-if="isSuper" class="tdata options">
						<button class="btn btn-sm btn-outline-success" @click="login(data)"><i class="fa fa-unlock-alt"></i></button>
						<router-link :to="'center/payment/' + data['id']">
							<button class="btn btn-sm btn-outline-primary"><i class="fa fa-money"></i></button>
						</router-link>
						<button class="btn btn-sm btn-outline-warning" @click="showAddEdit(data)">
							<i class="fa fa-edit"></i>
						</button>

						<button class="btn btn-sm btn-outline-info" @click="showManageRadiologist(data)">
							<i class="fa fa-medkit"></i>
						</button>

						
						<button class="btn btn-sm btn-outline-success" @click="showAssignCardiologist(data)">
							<i class="fa fa-heartbeat"></i>
						</button>
						<button class="btn btn-sm btn-outline-success" @click="showAssignNeurologist(data)">
							<i class="fa-solid fa-brain"></i>
						</button>

						<button class="btn btn-sm btn-outline-danger" @click="editId = data['id']; deleteModal.show();">
							<i class="fa fa-trash"></i>
						</button>
					</div>
				</template>
			</Column>
		</DataTable>

		<div class="modal fade" ref="deleteModal" tabindex="-1">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header bg-danger text-white">
						<h5 class="modal-title">Delete Confirmation</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body bg-white text-body text-center font-weight-bold font-1xl">
						<div class="text-danger" style="font-weight: bold; text-align: left; margin-bottom: 8px;">Record ID:
							{{ editId }}</div>
						You are about to delete this record, Are you sure?
						<br>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
						<button type="button" class="btn btn-danger" @click="deleteRecord">Delete</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade" ref="manageRadiologistModal" tabindex="-1">
			<div class="modal-dialog modal-md">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Manage Radiologist</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body bg-white text-body font-weight-bold font-1xl">
						<form v-if="fdata.blocked_doctor_ids && typeof fdata.blocked_doctor_ids == 'object'">

							<div style="padding-bottom: 10px;">
								<label>
									<input type="radio" name="prefer_radiologist" :value="0" v-model="fdata.prefer_radiologist_id"> Remove Prefer Radiologist
								</label>
							</div>


							<div v-for="d in doctors" :key="d.id" class="clearfix" style="margin-bottom: 10px;">
								<button v-if="!fdata.blocked_doctor_ids.includes(d.id+'')" class="btn btn-sm btn-danger pull-right" @click="fdata.blocked_doctor_ids.push(d.id+''); fdata.prefer_radiologist_id = fdata.prefer_radiologist_id == d.id ? 0 : fdata.prefer_radiologist_id;">Block</button>
								<button v-if="fdata.blocked_doctor_ids.includes(d.id+'')" class="btn btn-sm btn-success pull-right" @click="fdata.blocked_doctor_ids.splice(fdata.blocked_doctor_ids.indexOf(d.id+''), 1)">Unblock</button>
								<label :style="{'color': fdata.blocked_doctor_ids.includes(d.id+'') ? 'red' : 'black'}">
									<input v-if="!fdata.blocked_doctor_ids.includes(d.id+'')" type="radio" name="prefer_radiologist" :value="d.id" v-model="fdata.prefer_radiologist_id"> {{d.name}}
								</label>
							</div>

						</form>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
						<button type="button" class="btn btn-primary" @click="saveAssign">Save</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade" ref="assignModalCardiologist" tabindex="-1">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Assign Cardiologist</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body bg-white text-body text-center font-weight-bold font-1xl">

						<PickList v-model="fdata.cardiologist_ids" dataKey="id">
							<template #sourceHeader>
								Available
							</template>
							<template #targetHeader>
								Selected
							</template>
							<template #item="slotProps">
								<div>
									<div>
										{{ slotProps.item.name }}
									</div>
								</div>
							</template>
						</PickList>

					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
						<button type="button" class="btn btn-primary" @click="saveAssign">Save</button>
					</div>
				</div>
			</div>
		</div>
		<div class="modal fade" ref="assignModalPulmonologist" tabindex="-1">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Assign Pulmonologist</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body bg-white text-body text-center font-weight-bold font-1xl">

						<PickList v-model="fdata.pulmonologist_ids" dataKey="id">
							<template #sourceHeader>
								Available
							</template>
							<template #targetHeader>
								Selected
							</template>
							<template #item="slotProps">
								<div>
									<div>
										{{ slotProps.item.name }}
									</div>
								</div>
							</template>
						</PickList>

					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
						<button type="button" class="btn btn-primary" @click="saveAssign">Save</button>
					</div>
				</div>
			</div>
		</div>
		<div class="modal fade" ref="assignModalNeurologist" tabindex="-1">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Assign Neurologist</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body bg-white text-body text-center font-weight-bold font-1xl">

						<PickList v-model="fdata.neurologist_ids" dataKey="id">
							<template #sourceHeader>
								Available
							</template>
							<template #targetHeader>
								Selected
							</template>
							<template #item="slotProps">
								<div>
									<div>
										{{ slotProps.item.name }}
									</div>
								</div>
							</template>
						</PickList>

					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
						<button type="button" class="btn btn-primary" @click="saveAssign">Save</button>
					</div>
				</div>
			</div>
		</div>

		<form action="" @submit.prevent="putCenter">
			<div class="modal fade" id="exampleModal" ref="addModal" tabindex="-1" aria-labelledby="exampleModalLabel"
				aria-hidden="true">
				<div class="modal-dialog modal-xl">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="exampleModalLabel">
								<div v-if="editId == 0">Add Center</div>
								<div v-else>Edit Center</div>
							</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">

							<div class="container">
								<div class="row">
									<div class="col-sm-6">
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.name?.hasError && fcontrol.name.touched }"
												v-model="fdata.name" @keyup="makeCenterCode" @blur="fcontrol.name.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.name?.hasError">
												<span v-if="fcontrol.name.errors.required">You must enter name</span>
												<span v-if="fcontrol.name.errors.max">Name can contain maximum
													{{ fcontrol.name.errors.max.maxLength }} characters</span>
											</div>
											<label>Name</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.code?.hasError && fcontrol.code.touched }"
												v-model="fdata.code" @blur="fcontrol.code.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.code?.hasError">
												<span v-if="fcontrol.code.errors.required">You must enter code</span>
												<span v-if="fcontrol.code.errors.max">Code can contain maximum
													{{ fcontrol.code.errors.max.maxLength }} characters</span>
											</div>
											<label>Code</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.email?.hasError && fcontrol.email.touched }"
												v-model="fdata.email" @blur="fcontrol.email.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.email?.hasError">
												<span v-if="fcontrol.email.errors.required">You must enter email</span>
												<span v-if="fcontrol.email.errors.max">Email can contain maximum
													{{ fcontrol.email.errors.max.maxLength }} characters</span>
											</div>
											<label>Email</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.password?.hasError && fcontrol.password.touched }"
												v-model="fdata.password" @blur="fcontrol.password.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.password?.hasError">
												<span v-if="fcontrol.password.errors.required">You must enter password</span>
												<span v-if="fcontrol.password.errors.max">Password can contain maximum
													{{ fcontrol.password.errors.max.maxLength }} characters</span>
											</div>
											<label>Password</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.contact_person?.hasError && fcontrol.contact_person.touched }"
												v-model="fdata.contact_person" @blur="fcontrol.contact_person.touched = true"
												placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.contact_person?.hasError">
												<span v-if="fcontrol.contact_person.errors.required">You must enter contact
													person</span>
												<span v-if="fcontrol.contact_person.errors.max">Contact Person can contain maximum
													{{ fcontrol.contact_person.errors.max.maxLength }} characters</span>
											</div>
											<label>Contact Person</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.phone?.hasError && fcontrol.phone.touched }"
												v-model="fdata.phone" @blur="fcontrol.phone.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.phone?.hasError">
												<span v-if="fcontrol.phone.errors.required">You must enter phone</span>
												<span v-if="fcontrol.phone.errors.max">Phone can contain maximum
													{{ fcontrol.phone.errors.max.maxLength }} characters</span>
											</div>
											<label>Phone</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.address?.hasError && fcontrol.address.touched }"
												v-model="fdata.address" @blur="fcontrol.address.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.address?.hasError">
												<span v-if="fcontrol.address.errors.required">You must enter address</span>
												<span v-if="fcontrol.address.errors.max">Address can contain maximum
													{{ fcontrol.address.errors.max.maxLength }} characters</span>
											</div>
											<label>Address</label>
										</div>
										<div class="form-group ui-float-label">
											<input readonly type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.created_at?.hasError && fcontrol.created_at.touched }"
												v-model="fdata.created_at" @blur="fcontrol.created_at.touched = true"
												placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.created_at?.hasError">

											</div>
											<label>Created At</label>
										</div>
										<div class="form-group ui-float-label">
											<select class="form-control"
												:class="{ 'is-invalid': fcontrol.is_active?.hasError && fcontrol.is_active.touched }"
												v-model="fdata.is_active" @blur="fcontrol.is_active.touched = true">
												<option value="" disabled selected>Select Is Active</option>
												<option value="y">Yes</option>
												<option value="n">No</option>
											</select>
											<div class="invalid-feedback" v-if="fcontrol.is_active?.hasError">
												<span v-if="fcontrol.is_active.errors.required">You must enter is active</span>
												<span v-if="fcontrol.is_active.errors.in">Invalid is active</span>
											</div>
											<label>Is Active</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="number" class="form-control"
												:class="{ 'is-invalid': fcontrol.minimum_balance?.hasError && fcontrol.minimum_balance.touched }"
												v-model="fdata.minimum_balance" @blur="fcontrol.minimum_balance.touched = true"
												placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.minimum_balance?.hasError">
												<span v-if="fcontrol.minimum_balance.errors.required">
													You must enter miminum balance
												</span>
											</div>
											<label>Minimum Balance</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="number" class="form-control"
												:class="{ 'is-invalid': fcontrol.custom_plan?.hasError && fcontrol.custom_plan.touched }"
												v-model="fdata.custom_plan" @blur="fcontrol.custom_plan.touched = true"
												placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.custom_plan?.hasError">
												<span v-if="fcontrol.custom_plan.errors.required">
													You must enter custom plan
												</span>
											</div>
											<label>Custom Plan</label>
										</div>

										<div class="form-group ui-float-label">
											<select class="form-control"
												:class="{ 'is-invalid': fcontrol.show_disclaimer?.hasError && fcontrol.show_disclaimer.touched }"
												v-model="fdata.show_disclaimer" @blur="fcontrol.show_disclaimer.touched = true">
												<option value="" disabled selected>Select Is Active</option>
												<option value="y">Yes</option>
												<option value="n">No</option>
											</select>
											<div class="invalid-feedback" v-if="fcontrol.show_disclaimer?.hasError">
												<span v-if="fcontrol.show_disclaimer.errors.required">You must select show
													disclaimer</span>
												<span v-if="fcontrol.show_disclaimer.errors.in">Invalid show disclaimer</span>
											</div>
											<label>Show Disclaimer</label>
										</div>
										<div class="ecg-price-box">
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.cardiology_price?.hasError && fcontrol.cardiology_price.touched }"
													v-model="fdata.cardiology_price" @blur="fcontrol.cardiology_price.touched = true"
													placeholder=" " :readonly="!!(editId && !isSuper)" @keyup="calculateAgentPrice('cardiology_price')" />
												<div class="invalid-feedback" v-if="fcontrol.cardiology_price?.hasError">
													<span v-if="fcontrol.cardiology_price.errors.required">You must enter ECG test
														price</span>
												</div>
												<label>ECG Test Price</label>
											</div>
											<div class="form-group ui-float-label" :class="{invisible: !isSuper}">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.cardiology_doctor_price?.hasError && fcontrol.cardiology_doctor_price.touched }"
													v-model="fdata.cardiology_doctor_price"
													@blur="fcontrol.cardiology_doctor_price.touched = true" placeholder=" " />
												<div class="invalid-feedback" v-if="fcontrol.cardiology_doctor_price?.hasError">
													<span v-if="fcontrol.cardiology_doctor_price.errors.required">You must enter
														cardiology doctor price</span>
													<span v-if="fcontrol.cardiology_doctor_price.errors.notgt">Price can not be
														grater than {{ fcontrol.cardiology_doctor_price.errors.notgt.check }}</span>
												</div>
												<label>ECG Doctor Price</label>
											</div>


											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.tmt_price?.hasError && fcontrol.tmt_price.touched }"
													v-model="fdata.tmt_price" @blur="fcontrol.tmt_price.touched = true"
													placeholder=" " :readonly="!!(editId && !isSuper)" @keyup="calculateAgentPrice('tmt_price')" />
												<div class="invalid-feedback" v-if="fcontrol.tmt_price?.hasError">
													<span v-if="fcontrol.tmt_price.errors.required">You must enter TMT test
														price</span>
												</div>
												<label>TMT Test Price</label>
											</div>
											<div class="form-group ui-float-label" :class="{invisible: !isSuper}">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.tmt_doctor_price?.hasError && fcontrol.tmt_doctor_price.touched }"
													v-model="fdata.tmt_doctor_price"
													@blur="fcontrol.tmt_doctor_price.touched = true" placeholder=" " />
												<div class="invalid-feedback" v-if="fcontrol.tmt_doctor_price?.hasError">
													<span v-if="fcontrol.tmt_doctor_price.errors.required">You must enter
														TMT doctor price</span>
													<span v-if="fcontrol.tmt_doctor_price.errors.notgt">Price can not be
														grater than {{ fcontrol.tmt_doctor_price.errors.notgt.check }}</span>
												</div>
												<label>TMT Doctor Price</label>
											</div>


											<div style="position: relative; z-index: 99999999 !important;">
												<div class="form-group ">
													<span class="p-fluid p-float-label">
														<AutoComplete forceSelection :multiple="true" v-model="selectedCardiologists"
															:suggestions="filteredCardiologists" @complete="searchCardiologist($event)"
															field="name" />
														<label>Selected Cardiologists</label>
													</span>
												</div>
											</div>
										</div>

										<div class="pft-price-box">
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.pulmonology_price?.hasError && fcontrol.pulmonology_price.touched }"
													v-model="fdata.pulmonology_price" @blur="fcontrol.pulmonology_price.touched = true"
													placeholder=" " :readonly="!!(editId && !isSuper)" @keyup="calculateAgentPrice('pulmonology_price')" />
												<div class="invalid-feedback" v-if="fcontrol.pulmonology_price?.hasError">
													<span v-if="fcontrol.pulmonology_price.errors.required">You must enter PFT test
														price</span>
												</div>
												<label>PFT Test Price</label>
											</div>
											<div class="form-group ui-float-label" :class="{invisible: !isSuper}">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.pulmonology_doctor_price?.hasError && fcontrol.pulmonology_doctor_price.touched }"
													v-model="fdata.pulmonology_doctor_price"
													@blur="fcontrol.pulmonology_doctor_price.touched = true" placeholder=" " />
												<div class="invalid-feedback" v-if="fcontrol.pulmonology_doctor_price?.hasError">
													<span v-if="fcontrol.pulmonology_doctor_price.errors.required">You must enter
														pulmonology doctor price</span>
													<span v-if="fcontrol.pulmonology_doctor_price.errors.notgt">Price can not be
														grater than {{ fcontrol.pulmonology_doctor_price.errors.notgt.check }}</span>
												</div>
												<label>PFT Doctor Price</label>
											</div>


											<div style="position: relative; z-index: 99999999 !important;">
												<div class="form-group ">
													<span class="p-fluid p-float-label">
														<AutoComplete forceSelection :multiple="true" v-model="selectedPulmonologists"
															:suggestions="filteredPulmonologists" @complete="searchPulmonologist($event)"
															field="name" />
														<label>Selected Pulmonologists</label>
													</span>
												</div>
											</div>
										</div>

										<div class="eeg-price-box">
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.neurology_price?.hasError && fcontrol.neurology_price.touched }"
													v-model="fdata.neurology_price" @blur="fcontrol.neurology_price.touched = true"
													placeholder=" " :readonly="!!(editId && !isSuper)" @keyup="calculateAgentPrice('neurology_price')" />
												<div class="invalid-feedback" v-if="fcontrol.neurology_price?.hasError">
													<span v-if="fcontrol.neurology_price.errors.required">You must enter EEG test
														price</span>
												</div>
												<label>EEG Test Price</label>
											</div>
											<div class="form-group ui-float-label" :class="{invisible: !isSuper}">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.neurology_doctor_price?.hasError && fcontrol.neurology_doctor_price.touched }"
													v-model="fdata.neurology_doctor_price"
													@blur="fcontrol.neurology_doctor_price.touched = true" placeholder=" " />
												<div class="invalid-feedback" v-if="fcontrol.neurology_doctor_price?.hasError">
													<span v-if="fcontrol.neurology_doctor_price.errors.required">You must enter
														neurology doctor price</span>
													<span v-if="fcontrol.neurology_doctor_price.errors.notgt">Price can not be
														grater than {{ fcontrol.neurology_doctor_price.errors.notgt.check }}</span>
												</div>
												<label>EEG Doctor Price</label>
											</div>


											<div style="position: relative; z-index: 99999999 !important;">
												<div class="form-group ">
													<span class="p-fluid p-float-label">
														<AutoComplete forceSelection :multiple="true" v-model="selectedNeurologists"
															:suggestions="filteredNeurologists" @complete="searchNeurologist($event)"
															field="name" />
														<label>Selected Neurologists</label>
													</span>
												</div>
											</div>
										</div>
										<div hidden class="form-group">
											<button class="btn btn-info" @click="applyDefaultPrice" type="button">Apply Default
												Price</button>
										</div>

									</div>
									<div class="col-sm-6">
										<div class="center-price-box">
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.price_single_view?.hasError && fcontrol.price_single_view.touched }"
													v-model="fdata.price_single_view" @blur="fcontrol.price_single_view.touched = true"
													placeholder=" " :readonly="!!(editId && !isSuper)" @keyup="doubleRate('single_view'); calculateAgentPrice('price_single_view')" />
												<div class="invalid-feedback" v-if="fcontrol.price_single_view?.hasError">
													<span v-if="fcontrol.price_single_view.errors.required">You must enter price
														single view</span>
												</div>
												<label>Price Single View</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.price_double_view?.hasError && fcontrol.price_double_view.touched }"
													v-model="fdata.price_double_view" @blur="fcontrol.price_double_view.touched = true"
													placeholder=" " :readonly="!!(editId && !isSuper)" @keyup="calculateAgentPrice('price_double_view')" />
												<div class="invalid-feedback" v-if="fcontrol.price_double_view?.hasError">
													<span v-if="fcontrol.price_double_view.errors.required">You must enter price
														double view</span>
												</div>
												<label>Price Double View</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.price_multi_view?.hasError && fcontrol.price_multi_view.touched }"
													v-model="fdata.price_multi_view" @blur="fcontrol.price_multi_view.touched = true"
													placeholder=" " :readonly="!!(editId && !isSuper)" @keyup="doubleRate('multi_view'); calculateAgentPrice('price_multi_view')" />
												<div class="invalid-feedback" v-if="fcontrol.price_multi_view?.hasError">
													<span v-if="fcontrol.price_multi_view.errors.required">You must enter price multi
														view</span>
												</div>
												<label>Price Multi View</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.price_both_view?.hasError && fcontrol.price_both_view.touched }"
													v-model="fdata.price_both_view" @blur="fcontrol.price_both_view.touched = true"
													placeholder=" " :readonly="!!(!editId || (editId && !isSuper))" @keyup="calculateAgentPrice('price_both_view')" />
												<div class="invalid-feedback" v-if="fcontrol.price_both_view?.hasError">
													<span v-if="fcontrol.price_both_view.errors.required">You must enter price both
														view</span>
												</div>
												<label>Price Both View</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.price_other_view?.hasError && fcontrol.price_other_view.touched }"
													v-model="fdata.price_other_view" @blur="fcontrol.price_other_view.touched = true"
													placeholder=" " :readonly="!!(!editId || (editId && !isSuper))" @keyup="calculateAgentPrice('price_other_view')" />
												<div class="invalid-feedback" v-if="fcontrol.price_other_view?.hasError">
													<span v-if="fcontrol.price_other_view.errors.required">You must enter price other
														view</span>
												</div>
												<label>Price Other View</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.price_opg?.hasError && fcontrol.price_opg.touched }"
													v-model="fdata.price_opg" @blur="fcontrol.price_opg.touched = true"
													placeholder=" " :readonly="!!(editId && !isSuper)" @keyup="calculateAgentPrice('price_opg')" />
												<div class="invalid-feedback" v-if="fcontrol.price_opg?.hasError">
													<span v-if="fcontrol.price_opg.errors.required">You must enter price OPG</span>
												</div>
												<label>Price OPG</label>
											</div>
										</div>
										<div class="doctor-price-box" :class="{invisible: !isSuper}">
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.doctor_price_single_view?.hasError && fcontrol.doctor_price_single_view.touched }"
													v-model="fdata.doctor_price_single_view" @blur="fcontrol.doctor_price_single_view.touched = true" 
													placeholder=" " @keyup="doubleRate('doctor_single_view')" />
												<div class="invalid-feedback" v-if="fcontrol.doctor_price_single_view?.hasError">
													<span v-if="fcontrol.doctor_price_single_view.errors.required">You must enter
														doctor price single view</span>
													<span v-if="fcontrol.doctor_price_single_view.errors.notgt">Price can not be
														grater than {{ fcontrol.doctor_price_single_view.errors.notgt.check }}</span>
												</div>
												<label>Doctor Price Single View</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.doctor_price_double_view?.hasError && fcontrol.doctor_price_double_view.touched }"
													v-model="fdata.doctor_price_double_view"
													@blur="fcontrol.doctor_price_double_view.touched = true" placeholder=" " />
												<div class="invalid-feedback" v-if="fcontrol.doctor_price_double_view?.hasError">
													<span v-if="fcontrol.doctor_price_double_view.errors.required">You must enter
														doctor price double view</span>
													<span v-if="fcontrol.doctor_price_double_view.errors.notgt">Price can not be
														grater than {{ fcontrol.doctor_price_double_view.errors.notgt.check }}</span>
												</div>
												<label>Doctor Price Double View</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.doctor_price_multi_view?.hasError && fcontrol.doctor_price_multi_view.touched }"
													v-model="fdata.doctor_price_multi_view" @blur="fcontrol.doctor_price_multi_view.touched = true" 
													placeholder=" " @keyup="doubleRate('doctor_multi_view')" />
												<div class="invalid-feedback" v-if="fcontrol.doctor_price_multi_view?.hasError">
													<span v-if="fcontrol.doctor_price_multi_view.errors.required">You must enter
														doctor price multi view</span>
													<span v-if="fcontrol.doctor_price_multi_view.errors.notgt">Price can not be
														grater than {{ fcontrol.doctor_price_multi_view.errors.notgt.check }}</span>
												</div>
												<label>Doctor Price Multi View</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.doctor_price_both_view?.hasError && fcontrol.doctor_price_both_view.touched }"
													v-model="fdata.doctor_price_both_view"
													@blur="fcontrol.doctor_price_both_view.touched = true" placeholder=" " />
												<div class="invalid-feedback" v-if="fcontrol.doctor_price_both_view?.hasError">
													<span v-if="fcontrol.doctor_price_both_view.errors.required">You must enter
														doctor price both view</span>
													<span v-if="fcontrol.doctor_price_both_view.errors.notgt">Price can not be grater
														than {{ fcontrol.doctor_price_both_view.errors.notgt.check }}</span>
												</div>
												<label>Doctor Price Both View</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.doctor_price_other_view?.hasError && fcontrol.doctor_price_other_view.touched }"
													v-model="fdata.doctor_price_other_view"
													@blur="fcontrol.doctor_price_other_view.touched = true" placeholder=" " />
												<div class="invalid-feedback" v-if="fcontrol.doctor_price_other_view?.hasError">
													<span v-if="fcontrol.doctor_price_other_view.errors.required">You must enter
														doctor price other view</span>
													<span v-if="fcontrol.doctor_price_other_view.errors.notgt">Price can not be
														grater than {{ fcontrol.doctor_price_other_view.errors.notgt.check }}</span>
												</div>
												<label>Doctor Price Other View</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.doctor_price_opg?.hasError && fcontrol.doctor_price_opg.touched }"
													v-model="fdata.doctor_price_opg"
													@blur="fcontrol.doctor_price_opg.touched = true" placeholder=" " />
												<div class="invalid-feedback" v-if="fcontrol.doctor_price_opg?.hasError">
													<span v-if="fcontrol.doctor_price_opg.errors.required">You must enter
														doctor price OPG</span>
													<span v-if="fcontrol.doctor_price_opg.errors.notgt">Price can not be
														grater than {{ fcontrol.doctor_price_opg.errors.notgt.check }}</span>
												</div>
												<label>Doctor Price OPG</label>
											</div>
										</div>

										<div style="position: relative; z-index: 99999999 !important;">
											<div class="form-group ">
												<span class="p-fluid p-float-label">
													<AutoComplete forceSelection v-model="selectedAgent"
														:suggestions="filteredAgents" @complete="searchAgents($event)" field="name" />
													<label>Agent / Source</label>
												</span>
											</div>
										</div>

										<div class="agent-price-box" :class="{invisible: !isSuper}">
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.agent_price_single_view?.hasError && fcontrol.agent_price_single_view.touched }"
													v-model="fdata.agent_price_single_view"
													@blur="fcontrol.agent_price_single_view.touched = true" placeholder=" " />

												<label>Agent Commission Single View</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.agent_price_double_view?.hasError && fcontrol.agent_price_double_view.touched }"
													v-model="fdata.agent_price_double_view"
													@blur="fcontrol.agent_price_double_view.touched = true" placeholder=" " />

												<label>Agent Commission Double View</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.agent_price_multi_view?.hasError && fcontrol.agent_price_multi_view.touched }"
													v-model="fdata.agent_price_multi_view"
													@blur="fcontrol.agent_price_multi_view.touched = true" placeholder=" " />

												<label>Agent Commission Multi View</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.agent_price_both_view?.hasError && fcontrol.agent_price_both_view.touched }"
													v-model="fdata.agent_price_both_view"
													@blur="fcontrol.agent_price_both_view.touched = true" placeholder=" " />

												<label>Agent Commission Both View</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.agent_price_other_view?.hasError && fcontrol.agent_price_other_view.touched }"
													v-model="fdata.agent_price_other_view"
													@blur="fcontrol.agent_price_other_view.touched = true" placeholder=" " />

												<label>Agent Commission Other View</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.agent_price_opg?.hasError && fcontrol.agent_price_opg.touched }"
													v-model="fdata.agent_price_opg"
													@blur="fcontrol.agent_price_opg.touched = true" placeholder=" " />

												<label>Agent Commission OPG</label>
											</div>

											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.cardiology_agent_price?.hasError && fcontrol.cardiology_agent_price.touched }"
													v-model="fdata.cardiology_agent_price"
													@blur="fcontrol.cardiology_agent_price.touched = true" placeholder=" " />

												<label>Agent Commission ECG</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.tmt_agent_price?.hasError && fcontrol.tmt_agent_price.touched }"
													v-model="fdata.tmt_agent_price"
													@blur="fcontrol.tmt_agent_price.touched = true" placeholder=" " />

												<label>Agent Commission TMT</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.pulmonology_agent_price?.hasError && fcontrol.pulmonology_agent_price.touched }"
													v-model="fdata.pulmonology_agent_price"
													@blur="fcontrol.pulmonology_agent_price.touched = true" placeholder=" " />

												<label>Agent Commission PFT</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.neurology_agent_price?.hasError && fcontrol.neurology_agent_price.touched }"
													v-model="fdata.neurology_agent_price"
													@blur="fcontrol.neurology_agent_price.touched = true" placeholder=" " />

												<label>Agent Commission EEG</label>
											</div>
										</div>

									</div>
								</div>
							</div>


						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
							<button type="submit" class="btn btn-primary">Save</button>
						</div>
					</div>
				</div>
			</div>
		</form>

	</div>
</template>

<script>
import { Modal } from 'bootstrap'
import PickList from 'primevue/picklist';

import Calendar from 'primevue/calendar';

export default {
	components: {
		Calendar,
		PickList
	},
	data() {
		return {
			dateRange: "",
			loading: false,
			totalRecords: 0,
			centers: null,
			editId: 0,
			saving: false,

			doctors: [],

			cardiologists: [],
			filteredCardiologists: null,
			selectedCardiologists: [],

			pulmonologists: [],
			filteredPulmonologists: null,
			selectedPulmonologists: [],

			neurologists: [],
			filteredNeurologists: null,
			selectedNeurologists: [],

			agents: [],
			selectedAgent: null,
			filteredAgents: [],
			isSuper: false,

			defaultRates: {},


			filters: {
				'id': { value: '', matchMode: '=' },
				'code': { value: '', matchMode: 'like' },
				'name': { value: '', matchMode: 'like' },
				'email': { value: '', matchMode: 'like' },
				'password': { value: '', matchMode: 'like' },
				'contact_person': { value: '', matchMode: 'like' },
				'phone': { value: '', matchMode: 'like' },
				'address': { value: '', matchMode: 'like' },
				'created_at': { value: '', matchMode: 'like' },
				'is_active': { value: '', matchMode: 'like' },
				'agent_name': { value: '', matchMode: 'like' },
				'price_single_view': { value: '', matchMode: 'like' },
				'price_double_view': { value: '', matchMode: 'like' },
				'price_multi_view': { value: '', matchMode: 'like' },
				'price_both_view': { value: '', matchMode: 'like' },
				'price_other_view': { value: '', matchMode: 'like' },
				'doctor_price_single_view': { value: '', matchMode: 'like' },
				'doctor_price_double_view': { value: '', matchMode: 'like' },
				'doctor_price_multi_view': { value: '', matchMode: 'like' },
				'doctor_price_both_view': { value: '', matchMode: 'like' },
				'doctor_price_other_view': { value: '', matchMode: 'like' },
				'balance': { value: '', matchMode: 'like' },
			},
			addModal: null,
			deleteModal: null,
			manageRadiologistModal: null,
			assignModalCardiologist: null,
			assignModalPulmonologist: null,
			assignModalNeurologist: null,
			lazyParams: {},
			fdata: {
				id: '',
				code: '',
				name: '',
				email: '',
				password: '',
				contact_person: '',
				phone: '',
				address: '',
				created_at: '',
				is_active: 'y',
				minimum_balance: 0,
				custom_plan: 0,
				show_disclaimer: 'y',
				price_single_view: '',
				price_double_view: '',
				price_multi_view: '',
				price_both_view: '',
				price_other_view: '',
				price_opg: '',
				doctor_price_single_view: '',
				doctor_price_double_view: '',
				doctor_price_multi_view: '',
				doctor_price_both_view: '',
				doctor_price_other_view: '',
				doctor_price_opg: '',
				blocked_doctor_ids: '',
				cardiology_price: '',
				cardiology_doctor_price: '',
				tmt_price: '',
				tmt_doctor_price: '',
				cardiologist_ids: '',
				pulmonology_price: '',
				pulmonology_doctor_price: '',
				pulmonologist_ids: '',
				neurology_price: '',
				neurology_doctor_price: '',
				neurologist_ids: '',
				agent_id: '',
				agent_price_single_view: '',
				agent_price_double_view: '',
				agent_price_multi_view: '',
				agent_price_both_view: '',
				agent_price_other_view: '',
				agent_price_opg: '',
				cardiology_agent_price: '',
				tmt_agent_price: '',
				pulmonology_agent_price: '',
				neurology_agent_price: '',
				prefer_radiologist_id: '',
			},
			fvalidator: {
				id: '',
				code: 'required|max:10',
				name: 'required|max:50',
				email: 'required|max:50',
				password: 'required|max:30',
				contact_person: 'required|max:50',
				phone: 'required|max:20',
				address: 'required|max:255',
				created_at: '',
				is_active: 'required|in:y,n',
				minimum_balance: '',
				custom_plan: '',
				show_disclaimer: 'required|in:y,n',
				price_single_view: 'required',
				price_double_view: 'required',
				price_multi_view: 'required',
				price_both_view: 'required',
				price_other_view: 'required',
				price_opg: 'required',
				doctor_price_single_view: 'required|notgt:price_single_view',
				doctor_price_double_view: 'required|notgt:price_double_view',
				doctor_price_multi_view: 'required|notgt:price_multi_view',
				doctor_price_both_view: 'required|notgt:price_both_view',
				doctor_price_other_view: 'required|notgt:price_other_view',
				doctor_price_opg: 'required|notgt:price_opg',
				blocked_doctor_ids: '',
				cardiology_price: 'required',
				cardiology_doctor_price: 'required|notgt:cardiology_price',
				tmt_price: 'required',
				tmt_doctor_price: 'required|notgt:tmt_price',
				cardiologist_ids: '',
				pulmonology_price: 'required',
				pulmonology_doctor_price: 'required|notgt:pulmonology_price',
				pulmonologist_ids: '',
				neurology_price: 'required',
				neurology_doctor_price: 'required|notgt:neurology_price',
				neurologist_ids: '',
				agent_id: '',
				agent_price_single_view: '',
				agent_price_double_view: '',
				agent_price_multi_view: '',
				agent_price_both_view: '',
				agent_price_other_view: '',
				agent_price_opg: '',
				cardiology_agent_price: '',
				tmt_agent_price: '',
				pulmonology_agent_price: '',
				neurology_agent_price: '',
				prefer_radiologist_id: '',
			}
		}
	},
	customerService: null,
	async mounted() {
		this.addModal = new Modal(this.$refs.addModal);
		this.deleteModal = new Modal(this.$refs.deleteModal);
		this.manageRadiologistModal = new Modal(this.$refs.manageRadiologistModal);
		this.assignModalCardiologist = new Modal(this.$refs.assignModalCardiologist);
		this.assignModalPulmonologist = new Modal(this.$refs.assignModalPulmonologist);
		this.assignModalNeurologist = new Modal(this.$refs.assignModalNeurologist);

		this.lazyParams = {
			first: 0,
			rows: this.$refs.dt.rows,
			sortField: null,
			sortOrder: null,
			filters: this.filters,
		};
		this.loadLazyData();

		this.post("admin/load-radiologists", {
			first: 0,
			rows: 1000000,
			sortField: "id",
			sortOrder: -1,
			filters: {
				is_active: { value: 'y', matchMode: '=' }
			}
		}).then(res => {
			this.doctors = res.rows;
		});

		this.post("admin/load-cardiologists", {
			first: 0,
			rows: 1000000,
			sortField: "id",
			sortOrder: -1,
			filters: {
				is_active: { value: 'y', matchMode: '=' }
			}
		}).then(res => {
			this.cardiologists = res.rows;
		});

		this.post("admin/load-pulmonologists", {
			first: 0,
			rows: 1000000,
			sortField: "id",
			sortOrder: -1,
			filters: {
				is_active: { value: 'y', matchMode: '=' }
			}
		}).then(res => {
			this.pulmonologists = res.rows;
		});

		this.post("admin/load-neurologists", {
			first: 0,
			rows: 1000000,
			sortField: "id",
			sortOrder: -1,
			filters: {
				is_active: { value: 'y', matchMode: '=' }
			}
		}).then(res => {
			this.neurologists = res.rows;
		});


		this.post("admin/load-agents", {
			first: 0,
			rows: 1000000,
			sortField: "id",
			sortOrder: -1,
		}).then(res => {
			this.agents = res.rows;
		});

		
		this.defaultRates = await this.get("admin/load-defualt-rates");

		let mins = [ 'min_single_view', 'min_double_view', 'min_multi_view', 'min_both_view', 'min_other_view', 'min_opg', 'min_cardiology', 'min_tmt', 'min_pulmonology', 'min_neurology' ];
		let minCols = [ 'price_single_view', 'price_double_view', 'price_multi_view', 'price_both_view', 'price_other_view', 'price_opg', 'cardiology_price', 'tmt_price', 'pulmonology_price', 'neurology_price' ];
		

		for(let i=0; i < mins.length; i++){
			let m = Number(this.defaultRates[mins[i]]);
			if(m){
				this.fcontrol[minCols[i]].validators.push({
					name: 'gte',
					params: [m]
				})
			}
		}

	},
	methods: {
		login(data) {
			localStorage.center_access_token = JSON.stringify(data);
			window.open('/center/dashboard', "_blank").focus();
		},

		makeCenterCode(){
			if(this.editId) return;
			let code = '';

			let name = this.fdata.name ? this.fdata.name.toUpperCase().split(" ") : [];
			for(let p of name){
				if(p){
					code = code + p[0]
				}
			}

			let d = new Date();
			code = code + String(d.getFullYear()).substr(-2) + '' + (d.getMonth()+1) + '' + d.getDate();

			this.fdata.code = code;
		},

		enter() {
			let user = JSON.parse(window.localStorage.admin_access_token);
			this.isSuper = user.is_super == 'y';
		},

		calculateAgentPrice(col){
			let row = this.defaultRates;

			let mins = [ 'min_single_view', 'min_double_view', 'min_multi_view', 'min_both_view', 'min_other_view', 'min_opg', 'min_cardiology', 'min_tmt', 'min_pulmonology', 'min_neurology' ];
			let cols = [ 'price_single_view', 'price_double_view', 'price_multi_view', 'price_both_view', 'price_other_view', 'price_opg', 'cardiology_price', 'tmt_price', 'pulmonology_price', 'neurology_price' ];
			let agents = [ 'agent_price_single_view', 'agent_price_double_view', 'agent_price_multi_view', 'agent_price_both_view', 'agent_price_other_view', 'agent_price_opg', 'cardiology_agent_price', 'tmt_agent_price', 'pulmonology_agent_price', 'neurology_agent_price' ];

			let i = cols.indexOf(col);
			
			if(row[mins[i]] && this.fdata[cols[i]]){
				let c = this.fdata[cols[i]] - row[mins[i]];
				if(c < 0) c = 0;
				this.fdata[agents[i]] = c;
			}
		},

		async applyDefaultPrice() {
			let row = this.defaultRates;
			this.fpopulate(row);

			let mins = [ 'min_single_view', 'min_double_view', 'min_multi_view', 'min_both_view', 'min_other_view', 'min_opg', 'min_cardiology', 'min_tmt', 'min_pulmonology', 'min_neurology' ];
			let cols = [ 'price_single_view', 'price_double_view', 'price_multi_view', 'price_both_view', 'price_other_view', 'price_opg', 'cardiology_price', 'tmt_price', 'pulmonology_price', 'neurology_price' ];
			let agents = [ 'agent_price_single_view', 'agent_price_double_view', 'agent_price_multi_view', 'agent_price_both_view', 'agent_price_other_view', 'agent_price_opg', 'cardiology_agent_price', 'tmt_agent_price', 'pulmonology_agent_price', 'neurology_agent_price' ];

			for(let i=0; i<mins.length; i++){
				if(row[mins[i]] && row[cols[i]]){
					let c = row[cols[i]] - row[mins[i]];
					if(c < 0) c = 0;
					this.fdata[agents[i]] = c;
				}
			}

			let cardiologistIds = row.cardiologist_ids ? row.cardiologist_ids.split(",") : [];
			this.selectedCardiologists = this.cardiologists.filter((d) => {
				return cardiologistIds.includes(String(d.id));
			});

			let pulmonologistIds = row.pulmonologist_ids ? row.pulmonologist_ids.split(",") : [];
			this.selectedPulmonologists = this.pulmonologists.filter((d) => {
				return pulmonologistIds.includes(String(d.id));
			});

			let neurologistIds = row.neurologist_ids ? row.neurologist_ids.split(",") : [];
			this.selectedNeurologists = this.neurologists.filter((d) => {
				return neurologistIds.includes(String(d.id));
			});
		},
		doubleRate(type){
			console.log(this.fcontrol)
			if(type == 'single_view'){
				this.fdata.price_both_view = Number(this.fdata.price_single_view) * 2;
				this.calculateAgentPrice('price_both_view');
			}else if(type == 'multi_view'){
				this.fdata.price_other_view = Number(this.fdata.price_multi_view) * 2;
				this.calculateAgentPrice('price_other_view');
			}else if(type == 'doctor_single_view'){
				this.fdata.doctor_price_both_view = Number(this.fdata.doctor_price_single_view) * 2;
			}else if(type == 'doctor_multi_view'){
				this.fdata.doctor_price_other_view = Number(this.fdata.doctor_price_multi_view) * 2;
			}
		},
		putCenter() {
			if (this.fHasError() || this.saving) return;
			this.saving = true;

			let data = JSON.parse(JSON.stringify(this.fdata));
			if (this.editId) data.id = this.editId;

			data.cardiologist_ids = this.selectedCardiologists.map(d => d.id).join(",");
			data.pulmonologist_ids = this.selectedPulmonologists.map(d => d.id).join(",");
			data.neurologist_ids = this.selectedNeurologists.map(d => d.id).join(",");
			if (this.selectedAgent) data.agent_id = this.selectedAgent.id;
			if(!data.agent_id) data.agent_id = 0;

			this.post("admin/put-center", data).then(res => {
				this.saving = false;
				if (res.success) {
					this.addModal.hide();
					this.loadLazyData();
				} else if (res.codeExists) {
					alert("Center code already exists.")
				}
			});
		},

		showManageRadiologist(row){
			this.freset();
			this.editId = row ? row.id : 0;
			this.editRow = row ? row : null;
			if (row) this.fpopulate(row);

			this.fdata.blocked_doctor_ids = row.blocked_doctor_ids ? row.blocked_doctor_ids.split(",") : [];
			this.fdata.prefer_radiologist_id = row.prefer_radiologist_id;

			this.manageRadiologistModal.show();
		},

		


		showAssignCardiologist(row) {
			this.freset();
			this.editId = row ? row.id : 0;
			this.editRow = row ? row : null;
			if (row) this.fpopulate(row);

			this.fdata.cardiologist_ids = [[], []];

			let selectedCenters = row.cardiologist_ids ? row.cardiologist_ids.split(",") : [];
			for (let c of this.cardiologists) {
				if (selectedCenters.includes(String(c.id))) {
					this.fdata.cardiologist_ids[1].push(c)
				} else {
					this.fdata.cardiologist_ids[0].push(c)
				}
			}

			this.assignModalCardiologist.show();
		},
		showAssignPulmonologist(row) {
			this.freset();
			this.editId = row ? row.id : 0;
			this.editRow = row ? row : null;
			if (row) this.fpopulate(row);

			this.fdata.pulmonologist_ids = [[], []];

			let selectedCenters = row.pulmonologist_ids ? row.pulmonologist_ids.split(",") : [];
			for (let c of this.pulmonologists) {
				if (selectedCenters.includes(String(c.id))) {
					this.fdata.pulmonologist_ids[1].push(c)
				} else {
					this.fdata.pulmonologist_ids[0].push(c)
				}
			}

			this.assignModalPulmonologist.show();
		},
		showAssignNeurologist(row) {
			this.freset();
			this.editId = row ? row.id : 0;
			this.editRow = row ? row : null;
			if (row) this.fpopulate(row);

			this.fdata.neurologist_ids = [[], []];

			let selectedCenters = row.neurologist_ids ? row.neurologist_ids.split(",") : [];
			for (let c of this.neurologists) {
				if (selectedCenters.includes(String(c.id))) {
					this.fdata.neurologist_ids[1].push(c)
				} else {
					this.fdata.neurologist_ids[0].push(c)
				}
			}

			this.assignModalNeurologist.show();
		},
		saveAssign() {
			if (this.saving){
				console.log("Already saving.");
				return;
			}
			this.saving = true;

			let data = JSON.parse(JSON.stringify(this.fdata));

			
			data.id = this.editId;
			
			// if (typeof data.doctor_ids == 'object') data.doctor_ids = data.doctor_ids[1].map(d => d.id).join(",");
			if (typeof data.cardiologist_ids == 'object') data.cardiologist_ids = data.cardiologist_ids[1].map(d => d.id).join(",");
			if (typeof data.pulmonologist_ids == 'object') data.pulmonologist_ids = data.pulmonologist_ids[1].map(d => d.id).join(",");
			if (typeof data.neurologist_ids == 'object') data.neurologist_ids = data.neurologist_ids[1].map(d => d.id).join(",");
			
			if(data.blocked_doctor_ids && typeof data.blocked_doctor_ids == 'object') data.blocked_doctor_ids = data.blocked_doctor_ids.join(",");
			
			console.log(data);

			this.upload("admin/put-center", data, (err, res) => {
				this.saving = false;
				if (res.success) {
					// this.assignModalDoctor.hide();
					this.assignModalCardiologist.hide();
					this.assignModalPulmonologist.hide();
					this.assignModalNeurologist.hide();
					this.manageRadiologistModal.hide();
					this.loadLazyData();
				}
			});
		},

		searchDoctor(event) {
			if (!event.query.trim().length) {
				this.filteredDoctors = [...this.doctors];
			} else {
				this.filteredDoctors = this.doctors.filter((d) => {
					return d.name.toLowerCase().indexOf(event.query.toLowerCase()) != -1;
				});
			}
		},
		searchCardiologist(event) {
			if (!event.query.trim().length) {
				this.filteredCardiologists = [...this.cardiologists];
			} else {
				this.filteredCardiologists = this.cardiologists.filter((d) => {
					return d.name.toLowerCase().indexOf(event.query.toLowerCase()) != -1;
				});
			}
		},
		searchPulmonologist(event) {
			if (!event.query.trim().length) {
				this.filteredPulmonologists = [...this.pulmonologists];
			} else {
				this.filteredPulmonologists = this.pulmonologists.filter((d) => {
					return d.name.toLowerCase().indexOf(event.query.toLowerCase()) != -1;
				});
			}
		},
		searchNeurologist(event) {
			if (!event.query.trim().length) {
				this.filteredNeurologists = [...this.neurologists];
			} else {
				this.filteredNeurologists = this.neurologists.filter((d) => {
					return d.name.toLowerCase().indexOf(event.query.toLowerCase()) != -1;
				});
			}
		},
		searchAgents(event) {
			if (!event.query.trim().length) {
				this.filteredAgents = [...this.agents];
			} else {
				this.filteredAgents = this.agents.filter((d) => {
					return d.name.toLowerCase().indexOf(event.query.toLowerCase()) != -1;
				});
			}
		},
		showAddEdit(row) {
			this.freset();
			this.editId = row ? row.id : 0;
			// console.log(row);
			if (row) this.fpopulate(row);
			else{
				setTimeout(()=>{
					this.applyDefaultPrice();
				}, 500)
			}
			this.selectedDoctors = [];
			this.selectedCardiologists = [];
			this.selectedPulmonologists = [];
			this.selectedNeurologists = [];
			this.selectedAgent = null;
			if (row) {
				// let doctorIds = row.doctor_ids ? row.doctor_ids.split(",") : [];
				// this.selectedDoctors = this.doctors.filter((d) => {
				// 	return doctorIds.includes(String(d.id));
				// });

				let cardiologistIds = row.cardiologist_ids ? row.cardiologist_ids.split(",") : [];
				this.selectedCardiologists = this.cardiologists.filter((d) => {
					return cardiologistIds.includes(String(d.id));
				});

				let pulmonologistIds = row.pulmonologist_ids ? row.pulmonologist_ids.split(",") : [];
				this.selectedPulmonologists = this.pulmonologists.filter((d) => {
					return pulmonologistIds.includes(String(d.id));
				});

				let neurologistIds = row.neurologist_ids ? row.neurologist_ids.split(",") : [];
				this.selectedNeurologists = this.neurologists.filter((d) => {
					return neurologistIds.includes(String(d.id));
				});

				if (row.agent_id != 0) {
					this.selectedAgent = this.agents.find(a => a.id == row.agent_id);
				}
				// console.log(row.agent_id, this.selectedAgent);
			}
			this.addModal.show();
		},
		deleteRecord() {
			this.delete("admin/delete-center/" + this.editId).then(res => {
				this.saving = false;
				if (res.success) {
					this.deleteModal.hide();
					this.loadLazyData();
				}
			});
		},
		loadLazyData() {
			this.loading = true;
			let params = JSON.parse(JSON.stringify(this.lazyParams));
			for (let f in params.filters) {
				if (!params.filters[f].value.length) delete params.filters[f];
			}

			if (!params.sortField) {
				params.sortField = "id";
				params.sortOrder = -1;
			}

			if (this.dateRange[0] && this.dateRange[1]) {
				params.filters['centers.created_at'] = { value: [this.format(this.dateRange[0], "YYYY-MM-DD"), this.format(this.dateRange[1], "YYYY-MM-DD")], matchMode: "between" }
			}


			let modifyFilters = ['balance'];
			for (let c of modifyFilters) {
				if (!(c in params.filters) || typeof params.filters[c].value != 'string') continue;
				let v = params.filters[c].value;
				let m = params.filters[c].matchMode;
				if (v[0] == '=') {
					m = '=';
					v = v.substr(1);
				} else if (v[0] == '>') {
					m = '>';
					v = v.substr(1);
				} else if (v[0] == '<') {
					m = '<';
					v = v.substr(1);
				} else if (v.indexOf('-') != -1) {
					m = 'between';
					v = v.split("-");
				}

				params.filters[c].value = v;
				params.filters[c].matchMode = m;
			}



			params.joins = [
				{
					"fTable": "agents",
					"fKey": "agent_id",
					"fField": [["name", "agent_name"]]
				},
			]

			this.post("admin/load-centers", params).then(res => {
				this.loading = false;
				if (res.success) {
					this.totalRecords = res.totalRecords;
					this.centers = res.rows;
				}
			});
		},
		onPage(event) {
			this.lazyParams = event;
			this.loadLazyData();
		},
		onSort(event) {
			this.lazyParams = event;
			this.loadLazyData();
		},
		onFilter() {
			this.loading = true;
			this.lazyParams.filters = this.filters;
			this.loadLazyData();
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		}
	}
}
</script>
<style scoped>
.center-price-box {
	padding: 8px 12px;
	margin-bottom: 15px;
	background: #a4c4e3;
	border-radius: 10px;
}

.doctor-price-box {
	padding: 8px 12px;
	margin-bottom: 15px;
	background: #d9dfe6;
	border-radius: 10px;
}

.agent-price-box {
	padding: 8px 12px;
	margin-bottom: 15px;
	background: #eec9e9;
	border-radius: 10px;
}

.ecg-price-box {
	padding: 8px 12px;
	margin-bottom: 15px;
	background: #eeebc9;
	border-radius: 10px;
}

.pft-price-box {
	padding: 8px 12px;
	margin-bottom: 15px;
	background: #d1ffba;
	border-radius: 10px;
}

.eeg-price-box {
	padding: 8px 12px;
	margin-bottom: 15px;
	background: #c9eee7;
	border-radius: 10px;
}

</style>